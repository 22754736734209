@charset 'UTF-8';
/* Slider */
/* .slick-loading .slick-list
{

} */

/* Icons */
@font-face
{
    font-family: 'MapoGoldenPier';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/MapoGoldenPierA.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 48%;

    display: block;

    width: 50px;
    height: 30%;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .15;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .45;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* 왼쪽 */
.slick-prev
{
    left: -55px;
    z-index: 1;
    right: auto;
}
[dir='rtl'] .slick-prev
{
    right: -55px;
    left: auto;
}
.slick-prev:before
{
    content: ">";
    content: url("./assets/images/iconmonstr-arrow-left-64.svg");
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

/* 오른쪽 */
.slick-next
{

    right: -80px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -55px;
}
.slick-next:before
{
    content: ">";

    content: url("./assets/images/iconmonstr-arrow-right-64.svg");
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}


/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: 80px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    @media screen and (max-width: 768px) {
        bottom:100px;


    }

}
.slick-dots li
{
    position: relative;
    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 2px;
    padding: 0;
    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0px;
    line-height: 0;
    display: block;
    width: 10px;
    height: 10px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 0.8;
}
.slick-dots li button:before
{
    font-family: 'MapoGoldenPier';
    font-size: 3px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 12px;
    height: 12px;

    /* content: url("./assets/images/iconmonstr-circle-1.svg"); */
    background-image:  url("./assets/images/iconmonstr-circle-1.svg");
    background-size: 12px 12px;
    content:"";
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}
